import { Injectable } from "@angular/core";
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LandlordsApi {
    public apiUrl = '';
    searchString;
    page;
    pageSize;
    filters: any;
    suggestAutocompleteQuery;
    public apiParameter: any;
    listPageIndex;
    listPageSize;
    filter: any = null;

    constructor(private sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    /*****************************************************************
        getAllLandlords()
        param: 
        purpose : this method will call api to get all Landlords
    *******************************************************************/
    public getAllLandlords(landlordFilterCriteria) {
        // var object = '{}';
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(landlordFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Landlords/GetAll', body, options);
    }

    public getNextTenLandlords(page) {
       
        return this.http.post(this.apiUrl + '/api/Landlords/GetAll', { page: page });
    }

    /*****************************************************************
        getAllLandlordSuggestions()
        param:
        purpose : this method will call api to get all Landlords as per suggestion query
    *******************************************************************/
    public getAllLandlordSuggestions(object) {
        return this.http.post(this.apiUrl + '/api/Landlords/GetAll', object);

    }

    /*****************************************************************
        getLandlord()
        param: id: string
        purpose : this method will call api to get Landlord by id
    *******************************************************************/
    public getLandlord(uId: string) {
        return this.http.get(this.apiUrl + '/api/Landlords/Get/' + uId);
    }

    /*****************************************************************
        addLandlord()
        param: object: LandlordsModel
        purpose : this method will call api for add new Landlord
    *******************************************************************/
    public addNewLandlord(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Landlords/AddNew', body, options);
    }

    /*****************************************************************
        updateLandlord()
        param: object: LandlordsModel
        purpose : this method will call api for update existing Landlord
    *******************************************************************/
    public updateLandlord(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.put(this.apiUrl + '/api/Landlords/Update/' + object.uId, body, options);
    }

    /*****************************************************************
        deleteLandlord()
        param: object
        purpose : this method will call api to delete particular Landlord
    *******************************************************************/
    public deleteLandlord(object) {
        return this.http.delete(this.apiUrl + '/api/Landlords/Delete/' + object.uId + '?version=' + object.version);
    }
}