import { Injectable } from "@angular/core";
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PropertyManagerApi {
    public apiUrl = '';
    public apiParameter: any;
    listPageIndex;
    listPageSize;
    filter: any = null;
    searchString = '';
    querySearch = "";
    public authToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImY0ODI3NTU1ODY5MjNlZTg4MTIxMDNjYzA2MjZlNjJiIiwidHlwIjoiSldUIn0.eyJuYmYiOjE1NDcwMTc1NzIsImV4cCI6MTU0NzEwMzk3MiwiaXNzIjoiaHR0cHM6Ly9jZW50cmF0cmFja3N0cy5henVyZXdlYnNpdGVzLm5ldCIsImF1ZCI6WyJodHRwczovL2NlbnRyYXRyYWNrc3RzLmF6dXJld2Vic2l0ZXMubmV0L3Jlc291cmNlcyIsImFwaTEiXSwiY2xpZW50X2lkIjoicm8uY2xpZW50Iiwic3ViIjoiMjNkYWE4ZjAtNzAzMi00OTJkLWFmZjgtZmFmYTM0ZWFmY2U3IiwiYXV0aF90aW1lIjoxNTQ3MDE3NTcxLCJpZHAiOiJsb2NhbCIsIm9yZ1VJZCI6Ijg1MjUxNmQwLTQ2YmItNDJlZS04Zjg0LTVhOWFiYjI3ZDc2NiIsImlzQWRtaW4iOiJ0cnVlIiwiaXNTdXBlckFkbWluIjoidHJ1ZSIsInNjb3BlIjpbImFwaTEiXSwiYW1yIjpbInB3ZCJdfQ.YskkxFc73brgZ99VK5c-a3yENYN8fdzNCvPZUIuSPBJoD8dfU7auJbwiPMS36JEm-VikzY5KscvnyhOgaGHQPbj-BG_Qtz1fhktp4gSZFVvGOyMbKAsdi6F0nUieHvzpjYiXk4PMxVPet6h8hQXqmENbFNUOrLCs83yDLyg6zXzVVuiuOwupmU7NcpHRnmHVwiSVRHOIoVzqhIXHiQyC1AHarrsfTz5vjTA40Mfdrz4jKgcrXI9uYl1SDSndRC-1kzq4_rgPpsWXxzHNlK0U1FAfSjhlFISyS1enX4Sjo2lgRscWzl9gBw7T7JbjflhHpwCVUxHKG73OPCdSMGpOaA";

    constructor(private sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    /*****************************************************************
        getAllPropertyManager()
        param: 
        purpose : this method will call api to get all Property-Managers
    *******************************************************************/
    public getAllPropertyManager(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/PropertyManagers/GetAll', body, options);
    }

    /*****************************************************************
        getNextTenPropertyManager()
        param: 
        purpose : this method will call api to get all Property-Managers
    *******************************************************************/
    public getNextTenPropertyManager(page) {
        let headers = new HttpHeaders();
        headers.append('Authorization', `${this.authToken}`);
        return this.http.post(this.apiUrl + '/api/PropertyManagers/GetAll', { page: page }, { headers: headers });
    }

    /*****************************************************************
        getAllPropertyManager()
        param: 
        purpose : this method will call api to get all Property-Managers
    *******************************************************************/
    public getAllPropertyManagerSuggestions(object) {
        let headers = new HttpHeaders();
        headers.append('Authorization', `${this.authToken}`);
        return this.http.post(this.apiUrl + '/api/PropertyManagers/GetAll', object, { headers: headers });
    }

    /*****************************************************************
        getPropertyManager()
        param: id: string
        purpose : this method will call api to get Property-Manager by id
    *******************************************************************/
    public getPropertyManager(uId: string) {
        return this.http.get(this.apiUrl + '/api/PropertyManagers/Get/' + uId);
    }

    /*****************************************************************
        addPropertyManager()
        param: object: PropertyManagerModel
        purpose : this method will call api for add new Property-Manager
    *******************************************************************/
    public addNewPropertyManager(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/PropertyManagers/AddNew', body, options);
    }

    /*****************************************************************
        updatePropertyManager()
        param: object: PropertyManagerModel
        purpose : this method will call api for update existing Property-Manager
    *******************************************************************/
    public updatePropertyManager(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.put(this.apiUrl + '/api/PropertyManagers/Update/' + object.uId, body, options);
    }

    /*****************************************************************
       deletePropertyManager()
       param: object
       purpose : this method will call api to delete particular Property-Manager
    *******************************************************************/
    public deletePropertyManager(object) {
        return this.http.delete(this.apiUrl + '/api/PropertyManagers/Delete/' + object.uId + '?version=' + object.version);
    }

    /*****************************************************************
      uploadPropertyManagerLogo()
      param: event document to be added
      purpose : this method will upload the event document to db
    *******************************************************************/
    uploadPropertyManagerLogo(fileData) {
        const formData = new FormData();
        formData.append('file', fileData)
        return this.http.post(this.apiUrl + '/api/Properties/Upload', formData);
    }
}