import { Injectable } from "@angular/core";
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITenantResponse } from '@app/core/models/tenant.model';

@Injectable()
export class TenantApi {
    public apiUrl = '';

    public apiParameter: any;
    listPageIndex;
    listPageSize;
    filter: any = null;
    searchString = '';
    querySearch = "";

    constructor(private sharedService: SharedService, private http: HttpClient) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    /*****************************************************************
        getAllTenants()
        param: 
        purpose : this method will call api to get all Tenants
    *******************************************************************/
    // public getAllTenants() {
    //     return this.http.post(this.apiUrl + '/api/Tenants/GetAll', { pageSize: 20 });
    // }


    getAllTenants(userFilterCriteria) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(userFilterCriteria);
        return this.http.post(this.apiUrl + '/api/Tenants/GetAll', body, options);
    }


           /*****************************************************************
        getNextTenTenants()
        param: 
        purpose : this method will call api to get all Property-Managers
    *******************************************************************/
   public getNextTenTenants(page) {
    let headers = new HttpHeaders();
    return this.http.post(this.apiUrl + '/api/Tenants/GetAll', { page: page }, { headers: headers });
}


    /*****************************************************************
        getNextTenLandlords()
        param: page
        purpose : this method will call api to get all landlords for infinite scroll
    *******************************************************************/
    public getNextTenLandlords(page) {
        return this.http.post(this.apiUrl + '/api/Tenants/GetAll', { page: page });
    }

    /*****************************************************************
        getAllTenants()
        param: 
        purpose : this method will call api to get all Tenants
    *******************************************************************/
    getAllTenantsWithPageSize(value) {
        
        // const body = JSON.stringify(object);
        // return this.http.post(this.apiUrl + '/api/Tenants/GetAll', name);
        var name;
        if (typeof value === 'string') {
            name = value;
        }
        else {
            name = value.name;
        }

        let requestObject = {
            "suggestAutocompleteQuery": name,
            "pageSize": 100
        }

        return this.http.post(this.apiUrl + '/api/Tenants/GetAll', requestObject).pipe(
            tap((response: ITenantResponse) => {
                response.tenantSuggests = response.tenantSuggests
                    .map(tenant => tenant)
                    // Not filtering in the server since in-memory-web-api has somewhat restricted api
                    .filter(tenant => tenant.name.toLowerCase().includes(name.toLowerCase()))

                return response;
            })
        );
    }

    /*****************************************************************
        getTenant()
        param: id: string
        purpose : this method will call api to get Tenant by id
    *******************************************************************/
    public getTenant(uId: string) {
        return this.http.get(this.apiUrl + '/api/Tenants/Get/' + uId);
    }

    /*****************************************************************
        addTenant()
        param: object: TenantModel
        purpose : this method will call api for add new Tenant
    *******************************************************************/
    public addNewTenant(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.post(this.apiUrl + '/api/Tenants/AddNew', body, options);
    }

    /*****************************************************************
        updateTenant()
        param: object: TenantModel
        purpose : this method will call api for update existing Tenant
    *******************************************************************/
    public updateTenant(object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const body = JSON.stringify(object);
        return this.http.put(this.apiUrl + '/api/Tenants/Update/' + object.uId, body, options);
    }

    /*****************************************************************
        deleteTenant()
        param: object
        purpose : this method will call api to delete particular Tenant
    *******************************************************************/
    public deleteTenant(object) {
        return this.http.delete(this.apiUrl + '/api/Tenants/Delete/' + object.uId + '?version=' + object.version);
    }

    downloadTenantsAsExcel(dcriteria) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        const body = JSON.stringify(dcriteria);
        return this.http.post(this.apiUrl + '/api/Tenants/ExportTenantListAsExcel', body, { observe: 'response', responseType: 'blob', headers: headers });
    }

    /*************************************************************************
Purpose: to get properties to add user claims
***************************************************************************/
    getAllPropertiesToAddUser(uid: string) {
        const options = {
            headers: new HttpHeaders({
                'Content-type': 'application/json'
            })
        }
        return this.http.get(this.apiUrl + '/api/Properties/GetPropertiesForAddUser?orgUId=' + uid, options);
    }
}